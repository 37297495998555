import React, { Component } from "react"
import { graphql } from "gatsby"
import Layout from "../layouts"
import Seo from "../components/seo"
import Header from "../components/header"
import Articles from "../components/articles"
import PageTitle from "../components/pageTitle"
import PageIntro from "../components/pageIntro"

class NewsTemplate extends Component {
  constructor(props) {
    super(props)
    this.state = {
      postYear: "all",
      postHeight: "auto",
      postFilter: "all",
    }

    this.filterPosts = this.filterPosts.bind(this)
  }

  componentDidMount() {
    if (document.querySelectorAll(".block__content--post")) {
      // Make all of the blocks equal height
      const blocks = document.querySelectorAll(".block__content--post")

      function setMaxHeight() {
        let blockHeights = []
        blocks.forEach(el => {
          blockHeights.push(el.clientHeight)
        })
        return Math.max(...blockHeights)
      }
      // Update state passed down to posts as prop
      this.setState({ postHeight: setMaxHeight() })

      window.addEventListener("resize", () => {
        this.setState({ postHeight: setMaxHeight() })
      })
    }
  }

  filterPosts(event) {
    // Show/Hide blocks
    const filterVal = event.target.value
    let els = document.querySelectorAll("[data-year]")
    els.forEach(el => {
      if (filterVal === "all") {
        el.style.display = "block"
      } else {
        el.style.display = filterVal === el.dataset.year ? "block" : "none"
      }
    })

    this.setState({ postFilter: event.target.value })
  }

  render() {
    const introTitle = "PRHA News & Gallery",
      introDesc = "A selection of our news and photographs",
      posts = this.props.data.allWpPost.edges,
      seoTitle = introTitle,
      seoDesc = introDesc

    const postDates = posts.map(({ node }) => {
      const date = new Date(node.date)
      return date.getFullYear()
    })

    const uniqueDates = Array.from(new Set(postDates)) // remove duplicates from arr

    return (
      <Layout>
        <div className="page">
          <Seo seoTitle={seoTitle} seoDesc={seoDesc} />
          <Header />
          <PageTitle pageTitle={`News and Gallery`} />
          <PageIntro
            introTitle={introTitle}
            introDesc={`<p>${introDesc}</p>`}
          />

          <section className="section sectionWhite">
            <div className="containerFluid">
              <div className="row">
                <div className="col-bp1-12">
                  <div className="articlesFilter">
                    <div className="articlesFilter__inner">
                      <h3>Filter by</h3>
                      <select
                        className="articlesFilter__select"
                        onBlur={this.filterPosts}
                      >
                        <option value="all" defaultValue>
                          Year
                        </option>
                        {uniqueDates.map((date, i) => {
                          return (
                            <option key={i} value={date}>
                              {date}
                            </option>
                          )
                        })}
                      </select>
                    </div>
                  </div>
                </div>
              </div>

              <Articles
                postYear={this.state.postYear}
                postHeight={this.state.postHeight}
              />
            </div>
          </section>
        </div>
      </Layout>
    )
  }
}

export default NewsTemplate

export const query = graphql`
  query GET_POSTS($skip: Int!, $limit: Int!) {
    allWpPost(limit: $limit, skip: $skip, sort: { order: ASC, fields: id }) {
      edges {
        node {
          title
          slug
          date
        }
      }
    }
  }
`
