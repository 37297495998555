import React from "react"
import { StaticQuery, Link, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import LinkCog from "../components/linkCog"
import Placeholder from "../components/placeholder"

const Articles = props => (
  <StaticQuery
    query={graphql`
      query {
        allWpPost {
          edges {
            node {
              title
              content
              date(formatString: "MMMM DD, YYYY")
              slug
              featuredImage {
                node {
                  localFile {
                    name
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={data => (
      <div className="row">
        {data.allWpPost.edges.map(({ node }) => (
          <div
            key={node.slug}
            className="col-bp1-12 col-bp2-6 col-bp5-4"
            data-year={getYear(node.date)}
          >
            <div className="block">
              {featuredImage(node.featuredImage.node, `/${node.slug}`)}
              <div
                className="block__content block__content--post"
                style={{ height: props.postHeight }}
              >
                <h3 className="block__title">
                  <Link to={`/${node.slug}`}>{node.title}</Link>
                </h3>
                <p
                  dangerouslySetInnerHTML={{
                    __html: excerpt(node.content, 120),
                  }}
                ></p>
                <Link to={`/${node.slug}`} className="block__link">
                  <div className="block__linkCog">
                    <LinkCog />
                  </div>
                </Link>
              </div>
            </div>
          </div>
        ))}
      </div>
    )}
  />
)

function getYear(date) {
  const dateFormatted = new Date(date)
  return dateFormatted.getFullYear()
}

function featuredImage(imgURL, postSlug) {
  if (imgURL !== null) {
    const name = imgURL.localFile.name
    const imgRes = imgURL.localFile
    return (
      <Link to={postSlug}>
        <GatsbyImage
          className="block__img"
          image={getImage(imgRes)}
          key={imgRes.src}
          alt={name}
        />
      </Link>
    )
  } else {
    return (
      <Link to={postSlug}>
        <Placeholder />
      </Link>
    )
  }
}

function excerpt(snippet, length) {
  if (typeof document !== "undefined") {
    let el = document.createElement("div")
    el.innerHTML = snippet
    let text = el.textContent || el.innerText || ""
    return text.substring(0, length) + "&hellip;"
  }
}

export default Articles
